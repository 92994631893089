expert-statutory-reports-processors-ex kee-dropdown[name="periodicity"] div.form-group {
  margin-top: 0px;
  margin-bottom: 0px;
}

// expert-statutory-reports-processors-ex d300-processor .k-numerictextbox .k-numeric-wrap {
//   border-width: 1px;
// }

// expert-statutory-reports-processors-ex d300-processor .k-numerictextbox .k-numeric-wrap.k-state-focused {
//   box-shadow: none;
// }

expert-statutory-reports-processors-ex d100-processor .k-numerictextbox .k-numeric-wrap {
  border-width: 1px;
}

expert-statutory-reports-processors-ex d100-processor .k-numerictextbox .k-numeric-wrap.k-state-focused {
  box-shadow: none;
}

d398-processor kendo-grid#correctionsGrid div.materialize.form-group{
  margin-top: 0px;
  margin-bottom: 0px;
}

d398-processor kendo-grid#correctionsGrid span.k-input{
  padding: 0px;
}

dividend-report kendo-grid#agaDividendsGrid th.k-header-right {
  text-align: right !important;
}
/* kee-numerictextbox with this class wil align text to right */
.kee-numeric-textbox-right span.k-numeric-wrap input.k-input{
  text-align: right !important;
}

.drop-target {
  border-color: red;
  border-width: 2px;
  border-style: dashed;
}
