.font-size-smaller {
  font-size: .9rem !important;
}

.font-size-smallest {
  font-size: .8rem !important;
}

.font-size-1-5 {
  font-size: 1.5rem !important;
}

.font-size-1-1 {
  font-size: 1.1rem !important;
}

.font-size-1 {
  font-size: 1rem !important;
}

.font-size-1-2 {
  font-size: 1.2rem !important;
}

.font-size-1-3 {
  font-size: 1.3rem !important;
}

.font-size-1-4 {
  font-size: 1.4rem !important;
}

.font-size-1-5 {
  font-size: 1.5rem !important;
}

.font-size-1-6 {
  font-size: 1.6rem !important;
}

.font-size-1-7 {
  font-size: 1.7rem !important;
}

.font-size-1-8 {
  font-size: 1.8rem !important;
}

.font-size-1-9 {
  font-size: 1.9rem !important;
}

.font-size-2 {
  font-size: 2rem !important;
}
