
// Color Classes
@import '../mixins/colors';
@each $color_name, $color in $materialize-colors {
   @each $color_type, $color_value in $color{
    @if $color_type == "base" {
      .#{$color_name} {
        background-color: $color_value !important;
      }

      .#{$color_name}-text {
        color: $color_value !important;
      }
    }
    @else {
      .#{$color_name}.#{$color_type} {
        background-color: $color_value !important;
      }

      .#{$color_name}-text.text-#{$color_type} {
        color: $color_value !important;
      }
    }
  }
}

// Shade classes
@each $color, $color_value in $shades-group {
  .#{$color} {
    background-color: $color_value !important;
  }

  .#{$color}-text {
    color: $color_value !important;
  }
}

// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)
