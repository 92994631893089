@each $breakpoint in map-keys($grid-breakpoints) {
   @include media-breakpoint-up($breakpoint){
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix} {
      border: 1px solid $border-color !important;
    }

    .border#{$infix}-0 {
      border: 0;
    }

    .border#{$infix}-top {
      border-top: 1px solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0;
    }

    .border#{$infix}-right {
      border-right: 1px solid $border-color !important;
    }

    .border#{$infix}-right-0 {
      border-right: 0;
    }

    .border#{$infix}-bottom {
      border-bottom: 1px solid $border-color !important;
    }

    .border#{$infix}-bottom-0 {
      border-bottom: 0;
    }

    .border#{$infix}-left {
      border-left: 1px solid $border-color !important;
    }

    .border#{$infix}-left-0 {
      border-left: 0;
    }
  }
}
