// global variables
$images-path: "../../../../images/" !default;
@import 'saliente/mixins';

// bootstrap

//
// Color system
//
$primary: kee-color("cyan") !default;
$secondary: kee-color("pink", "accent-2") !default;
$success: kee-color("green", "accent-3") !default;
$danger: kee-color("red", "accent-3") !default;

$body-bg: #FFF !default; //#faf9fe !default;

$grid-bg: #FFF !default;
$grid-alt-rows-bg: #F9F5FF !default;
$grid-header-footer-bg: #F9F5FF !default;
$grid-border: transparent !default; //rgba(33, 37, 41, 0.125) !default;
$grid-header-border: rgba(33, 37, 41, 0.125) !default;
$grid-hover-selection: #F0E6FE !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

//$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px ) !default;
//$grid-breakpoints: ( xs: 0, sm: 768px, md: 992px, lg: 1200px, xl: 1400px ) !default;
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px, xxxl: 1600px ) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

//$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px ) !default;
//$container-max-widths: (xs: 540px, sm: 720px, md: 960px, lg: 1140px, xl: 1320px ) !default;
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1320px, xxxl: 1500px ) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 24 !default;
$grid-gutter-width: 20px !default;

// Grid colors
//
// Background and border color of the grid component
$grid-bg: $grid-bg;
$grid-border: $grid-border;

// Background and border color of alternating rows in grid
$grid-alt-bg: $grid-alt-rows-bg;
$grid-alt-border: $grid-border;

// Background color of the Grid sticky cell.
$grid-sticky-alt-bg: $grid-alt-rows-bg;
$grid-sticky-border: $grid-border;
$grid-sticky-hovered-bg: $grid-hover-selection;
$grid-sticky-selected-bg: $grid-hover-selection;
$grid-sticky-selected-hovered-bg: $grid-hover-selection;

// Background and border color of grid header
$grid-header-bg: $grid-header-footer-bg;
$grid-header-border: $grid-header-border;

// Background and border color of grid footer
$grid-footer-bg: $grid-header-footer-bg;
$grid-footer-border: $grid-border;

// Background and border color of hovered rows in grid
$grid-hovered-bg: $grid-hover-selection;
$grid-hovered-border: $grid-border;

//Background color of selected rows in grid
$grid-selected-bg: $grid-hover-selection;
$grid-selected-border: $grid-border;

$grid-cell-padding-y: 0.75rem !default;
$grid-cell-padding-x: 0.75rem !default;

$grid-header-padding-y: 0.75rem !default;
$grid-header-padding-x: 0.75rem !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius: 0 !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

//Background color of a component.
$component-bg: #FFF !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-base: $font-family-sans-serif !default;
// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

// Allows for customizing button radius independently from global border radius
$input-btn-padding-x: 1.5rem !default;
$input-btn-padding-x-sm: 1rem !default;
$input-btn-padding-x-lg: 1.7rem !default;

//$input-btn-focus-width: kee-box-shadow-value(1) !default;

$btn-box-shadow: kee-box-shadow-value(1) !default;
$btn-active-box-shadow: kee-box-shadow-value(1.5) !default;

$btn-border-radius: 0 !default;
$btn-border-radius-lg: 0 !default;
$btn-border-radius-sm: 0 !default;

// Forms
//
// Bootstrap
$label-font-size: .8rem;

$input-disabled-color: rgba(0,0,0, .26) !default;
$input-box-shadow: none !default;

$input-focus-border-color: $secondary;
$input-focused-shadow: none !default;

$input-border-color: kee-color("grey", "base") !default;
$input-placeholder-color: #495057; //lighten($input-border-color, 20%);

$input-danger-color: $danger !default;
$input-success-color: $success !default;
$input-padding-x: 0 !default;
$input-padding-y: 0 !default;

$input-default-width: 100% !default;

$dropdownlist-focused-border: none !default;
$dropdownlist-focused-shadow: none !default;
// Forms
//
// Kendo
$input-border-width: 0 !default;
$input-focused-border: $input-focus-border-color !default;

// Cards
//
$card-border-width: 0;
$card-border-radius: 0;

//
//
//
//
$radio-fill-color: $secondary !default;
$radio-empty-color: #5a5a5a !default;
//
//
//
//
//
//
//

$popup-shadow: none !default;
$popup-padding-y: 0.5rem !default;
$popup-padding-x: 0 !default;


$picker-select-padding-y: 0.375rem !default;
$picker-select-padding-x: 0.375rem !default;
$list-item-padding-y: 3px !default;
$list-item-padding-x: 1.5rem !default;
$list-line-height: 1.5em !default;

// kendo
$accent: $primary !default;
@import "@progress/kendo-theme-bootstrap/scss/variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

// masterview
$masterview-header-height: 64px !default;
$masterview-header-height-mobile: 56px !default;
$masterview-videos-height: 300px !default;
$masterview-sidebar-width: 240px;
$masterview-padding-tb: 16px !default;

//materialize
$materialize-kendo-widget-selector: ".k-colorpicker, .k-combobox, .k-dateinput, .k-datepicker, .k-datetimepicker, .k-dropdown, .k-multiselect, .k-numerictextbox, .k-maskedtextbox, .k-timepicker, .k-dropdowntree";
$materialize-kendo-input-selector: ".k-dateinput-wrap, .k-dropdown-wrap, .k-multiselect-wrap, .k-numeric-wrap, .k-picker-wrap";
$materialize-input-selector: "input.form-control, textarea.form-control, kendo-maskedtextbox.form-control, " + $materialize-kendo-input-selector;

$materialize-input-height: 3rem;
$materialize-inline-input-height: 2.25rem;
$materialize-input-icon-width: 3rem;