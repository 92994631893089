@import './utilities/borders';
@import './utilities/cursor';
@import './utilities/font';
@import './utilities/sizes';
@import './utilities/ulli';

.ws-pre-wrap {
  white-space: pre-wrap;
}

.ws-pre-line {
  white-space: pre-line;
}

.secondary-text {
  color: $secondary !important;
}
