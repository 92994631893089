
// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-size-root: 14px !default;
@import 'saliente/globals';
@import 'variables';

// Bootstrap styles
@import 'bootstrap/bootstrap';

// Kendo styles
@import "@progress/kendo-theme-bootstrap/scss/all";

// Saliente
@import 'saliente/buttons';
@import 'saliente/cards';
@import 'saliente/documents';
@import 'saliente/forms';
@import 'saliente/kgrid';
@import 'saliente/layout';
@import 'saliente/spinner';
@import 'saliente/user';
@import 'saliente/utilities';
@import 'saliente/waves';
@import 'saliente/z-levels';
@import 'custom/custom';

@import "ngx-toastr/toastr.css"