@mixin kee-input() {
  // General Styles
  border-color: $input-border-color;
  border-radius: 0;
  outline: none;
  width: 100%;

  font-size: 1rem;
  //margin: 0 0 15px;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: all .3s;
}
@mixin kee-materialize-input() {
  // General Styles
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $input-border-color;
  width: 100%;

  &:not(.autoheight) {
    height: $materialize-input-height;
  }
}

.materialize {
  &.form-group {
    position: relative;
    margin-top: 1rem;

    #{$materialize-input-selector} {
      @include kee-materialize-input();
      @include kee-input();
    }

    #{$materialize-kendo-input-selector} {
      .k-input {
        padding-left: 0;
        height: $materialize-input-height;
      }

      .k-select {
        background-color: transparent;
        border-left-width: 0;
      }
    }

    .k-dateinput-wrap {
      .k-input {
        padding-right: .25rem;
      }
    }

    &.input-active {
      > label {
        font-size: $label-font-size;
        transform: translateY(-140%);
        max-width: 100%;
      }
    }

    // input.k-textbox {
    //   @include kee-materialize-input();
    //   @include kee-input();
    // }

    // Disabled input style
    &.input-disabled,
    &.input-readonly {
      #{$materialize-input-selector} {
        //color: $input-disabled-color;
        border-bottom: none;
        //border-bottom: 1px dotted $input-disabled-color;

        .k-select {
          color: $input-disabled-color;
          display: none;
        }
      }

      i.comboboxClearButton {
        display: none;
      }

      //> label {
        //color: $input-disabled-color;
      //}
    }

    // Focused input style
    &.input-focused:not(.input-readonly):not(.input-disabled) {
      #{$materialize-input-selector} {
        border-bottom: 1px solid $input-focus-border-color;
        box-shadow: 0 1px 0 0 $input-focus-border-color;

        .k-select {
          color: $input-focus-border-color;
        }
      }

      #{$materialize-kendo-input-selector} {
        border-bottom-width: 2px !important;
      }

      .prefix,
      .suffix {
        color: $input-focus-border-color;
      }

      > label {
        color: $input-focus-border-color;
      }
    }

    // Valid Input Style
    &.input-focused.valid:not(:readonly):not(:disabled),
    &.valid:not(:readonly):not(:disabled) {
      #{$materialize-input-selector} {
        border-bottom: 1px solid $input-success-color;
        box-shadow: 0 1px 0 0 $input-success-color;
      }

      > label:after {
        content: attr(data-success);
        color: $input-success-color;
        opacity: 1;
      }
    }
    // Invalid Input Style
    &.input-focused.invalid,
    &.invalid {
      #{$materialize-input-selector} {
        border-bottom: 1px solid $input-danger-color;
        box-shadow: 0 1px 0 0 $input-danger-color;
      }

      #{$materialize-kendo-input-selector} {
        border-bottom-width: 2px !important;
      }

      > label:after {
        content: attr(data-error);
        color: $input-danger-color;
        opacity: 1;
      }
    }

    // Form Message Shared Styles
    > label {
      color: $input-border-color;
      position: absolute;
      top: .8rem;
      left: 0;
      //right: 0;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 1rem;
      cursor: text;
      @include transition(.2s ease-out);

      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 65px;
        opacity: 0;
        transition: .2s opacity ease-out, .2s color ease-out;
      }
    }
  }

  // Styling for input field wrapper
  &.form-group {
    // Prefix Icons
    .prefix,
    .suffix {
      position: absolute;
      width: $materialize-input-icon-width;
      font-size: 2/3 * $materialize-input-icon-width;
      @include transition(color .2s);
      @include media-breakpoint-down(lg) {
        ~ .form-control {
          width: 86%;
          width: calc(100% - #{$materialize-input-icon-width});
        }
      }
      @include media-breakpoint-down(sm) {
        ~ .form-control {
          width: 80%;
          width: calc(100% - #{$materialize-input-icon-width});
        }
      }

      ~ .form-control {
        width: 92%;
        width: calc(100% - #{$materialize-input-icon-width});

      }

      ~ textarea.form-control {
        padding-top: .8rem;
      }

      &.small {
        font-size: 1/3*$materialize-input-icon-width;
        width: 2/3*$materialize-input-icon-width;
        margin-top: 1/4 * $materialize-input-height;
      }
    }

    .suffix {
      right: 0;

      ~ .form-control {
        margin-right: $materialize-input-icon-width;
      }

      ~ label {
        margin-right: $materialize-input-icon-width;
      }
    }

    .prefix {
      ~ .form-control {
        margin-left: $materialize-input-icon-width;
      }

      ~ label {
        margin-left: $materialize-input-icon-width;
      }
    }

    // Default textarea
    textarea {
      width: 100%;
      background-color: transparent;
      overflow-y: hidden;
      /* prevents scroll bar flash */
      padding: 1.6rem 0;
      /* prevents text jump on Enter keypress */
      resize: vertical;
      padding-top: .9rem !important;

      &:not(.autoheight):not(.customResize) {
        height: initial !important;
        min-height: $materialize-input-height;
      }

      &.autoheight {
        resize: none;
      }
    }

    // For textarea autoresize
    .hiddendiv {
      display: none;
      white-space: pre-wrap;
      word-wrap: break-word;
      overflow-wrap: break-word;
      /* future version of deprecated 'word-wrap' */
      padding-top: 1.2rem;
      /* prevents text jump on Enter keypress */
    }

    kendo-maskedtextbox > input.k-textbox {
      background-color: transparent;
    }
  }
}

.no-materialize-margin {
  .form-group.materialize {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.inline-input {
  .form-group.materialize {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    #{$materialize-input-selector} {
      &:not(.autoheight) {
        height: $materialize-inline-input-height;
      }
    }

    #{$materialize-kendo-input-selector} {
      .k-input {
        height: $materialize-inline-input-height;
      }
    }
  }
}

//Kendo components

.k-widget.form-control {
  height: auto;
}

.k-widget.form-control.k-maskedtextbox {
  height: auto !important;
}

#{$materialize-input-selector},
.k-dropdown .k-dropdown-wrap {
  background-color: $body-bg;
  @include kee-input();
}

#{$materialize-kendo-widget-selector} {
  background-color: transparent !important;
}

#{$materialize-kendo-input-selector} {
  margin: 0 !important;

  .k-input {
    background-color: transparent !important;
    color: $input-color !important;
    padding: .375rem .5rem;
  }

  .k-select {
    background-color: transparent !important;
    color: $input-color !important;
    border: none;
  }
}

.k-dropdown-wrap {
  z-index: 2;

  .k-clear-value {
    display: none !important;
    // top: 0 !important;
    // bottom: 0 !important;
    // height: auto !important;
  }
}

.k-combobox {
  .k-dropdown-wrap {
    > .k-i-close {
      right: calc(16px + .75rem);
    }
  }
}

.k-popup.k-list-container.custom-height {
  height: 100%;

  kendo-list {
    height: 100%;

    .k-list-scroller {
      height: 100%;
      max-height: 100% !important;
    }

  }
}

::placeholder {
  color: $input-placeholder-color !important;
}

.no-resize {
  textarea {
    resize: none !important;
  }
}

kee-textarea {
  display: block;
  padding: 0;
}

.k-numerictextbox .k-state-disabled {
  opacity: 1;
  filter: none;
}

.k-datepicker.k-state-disabled {
  opacity: 1;
  filter: none;
}

.k-dropdown-wrap.k-state-disabled {
  opacity: 1;
  filter: none;
}

kee-textarea.k-state-disabled {
  opacity: 1;
  filter: none; 
}

kendo-maskedtextbox.k-state-disabled,
kendo-maskedtextbox.k-state-disabled > input.k-textbox {
  opacity: 1;
  filter: none; 
}