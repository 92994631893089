@function kee-box-shadow-value($depth: 1) {
   @if $depth == 0{
    @return none !important;
  }
  @else if $depth == 1 {
     @return 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }
  @else if $depth == 1.5 {
     @return 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
  @else if $depth == 2 {
     @return 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  @else if $depth == 3 {
     @return 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
  @else if $depth == 4 {
     @return 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  }
  @else if $depth == 5 {
     @return 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  }
}
@mixin kee-box-shadow($depth: 1) {
  box-shadow: kee-box-shadow-value($depth);
}
