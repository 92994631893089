@mixin minmax-size($property, $size) {
   @each $breakpoint in map-keys($grid-breakpoints){
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .min-#{$property}#{$infix}-#{$size} {
        min-#{$property}: #{$size}px !important;
      }

      .max-#{$property}#{$infix}-#{$size} {
        max-#{$property}: #{$size}px !important;
      }

      .#{$property}#{$infix}-#{$size} {
        #{$property}: #{$size}px !important;
      }
    }
  }

}
@include minmax-size( 'width', 25);
@include minmax-size( 'width', 50);
@include minmax-size( 'width', 75);
@include minmax-size( 'width', 100);
@include minmax-size( 'width', 125);
@include minmax-size( 'width', 150);
@include minmax-size( 'width', 175);
@include minmax-size( 'width', 200);
@include minmax-size( 'width', 225);
@include minmax-size( 'width', 250);
@include minmax-size( 'width', 275);
@include minmax-size( 'width', 300);
@include minmax-size( 'width', 325);
@include minmax-size( 'width', 350);
@include minmax-size( 'width', 375);
@include minmax-size( 'width', 400);
@include minmax-size( 'width', 425);
@include minmax-size( 'width', 450);
@include minmax-size( 'width', 475);
@include minmax-size( 'width', 500);
@include minmax-size( 'width', 525);
@include minmax-size( 'width', 550);
@include minmax-size( 'width', 575);
@include minmax-size( 'width', 600);
@include minmax-size( 'width', 625);
@include minmax-size( 'width', 650);
@include minmax-size( 'width', 675);
@include minmax-size( 'width', 700);
@include minmax-size( 'width', 725);
@include minmax-size( 'width', 750);
@include minmax-size( 'width', 775);
@include minmax-size( 'width', 800);
@include minmax-size( 'width', 825);
@include minmax-size( 'width', 850);
@include minmax-size( 'width', 875);
@include minmax-size( 'width', 900);

@include minmax-size( 'height', 25);
@include minmax-size( 'height', 50);
@include minmax-size( 'height', 75);
@include minmax-size( 'height', 100);
@include minmax-size( 'height', 125);
@include minmax-size( 'height', 150);
@include minmax-size( 'height', 175);
@include minmax-size( 'height', 200);
@include minmax-size( 'height', 225);
@include minmax-size( 'height', 250);
@include minmax-size( 'height', 275);
@include minmax-size( 'height', 300);
@include minmax-size( 'height', 325);
@include minmax-size( 'height', 350);
@include minmax-size( 'height', 375);
@include minmax-size( 'height', 400);
@include minmax-size( 'height', 425);
@include minmax-size( 'height', 450);
@include minmax-size( 'height', 475);
@include minmax-size( 'height', 500);
@include minmax-size( 'height', 525);
@include minmax-size( 'height', 550);
@include minmax-size( 'height', 575);
@include minmax-size( 'height', 600);
@include minmax-size( 'height', 625);
@include minmax-size( 'height', 650);
@include minmax-size( 'height', 675);
@include minmax-size( 'height', 700);
@include minmax-size( 'height', 725);
@include minmax-size( 'height', 750);
@include minmax-size( 'height', 775);
@include minmax-size( 'height', 800);
@include minmax-size( 'height', 825);
@include minmax-size( 'height', 850);
@include minmax-size( 'height', 875);
@include minmax-size( 'height', 900);