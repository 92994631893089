@mixin document-specimen($image-name) {
  &.uploaded:after {
    content: "";
    opacity: .3;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: url("#{$images-path}#{$image-name}");
  }
}
@mixin download-icon($image-name) {
  &:after {
    content: "";
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: url("#{$images-path}#{$image-name}");
  }
}
