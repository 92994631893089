.kee-spinner {
  &.overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100000;

    &.show {
      display: block;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #d3d3d3;
      opacity: .5;
    }

    .container {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10001;
    }
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 40px;
    text-align: center;
    font-size: 10px;

    > div {
      background-color: #e12444; //#5FD321;
      height: 100%;
      width: 6px;
      display: block;
      float: left;
      margin-left: 3px;
      opacity: 1;

      -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }

    .rect3 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    .rect4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .rect5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
  }
  @-webkit-keyframes sk-stretchdelay {
    0%,
    100%,
    40% {
      -webkit-transform: scaleY(0.4);
    }

    20% {
      -webkit-transform: scaleY(1);
    }
  }
  @keyframes sk-stretchdelay {
    0%,
    100%,
    40% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }

    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
}
