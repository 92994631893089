td[kendogridfiltercell] {
  padding: 1px;

  .k-dropdown-wrap {
    border: none !important;
    background-color: #FFF !important;

    .k-input {
      padding: 0 .25em;
    }
  }
}

.k-grid {
  border-color: rgba(33, 37, 41, 0.125);
}

.k-grid-header {
  border-color: rgba(33, 37, 41, 0.125);
  //border-right-color: transparent;

  .k-header {
    .k-link {
      // margin-left: 0;
      // margin-right: 0;
      // padding-left: 0;
      // padding-right: 0;
      text-align: center !important;
      display: block;
      text-overflow: ellipsis;

      .k-icon.k-i-sort-asc-sm,
      .k-icon.k-i-sort-desc-sm {
        margin: 0;
      }
    }
  }

  .k-grid-header-wrap thead tr:first-of-type {
    border-color: transparent;
  }

  .k-grid-filter {
    padding-right: 2px;
    width: auto;

    &:focus {
      box-shadow: none !important;
    }

    &.k-state-active {
      color: $primary;
      background-color: transparent;
    }
  }

  .k-filtercell-wrapper {
    .k-filtercell-operator {
      display: none;
    }

    .k-numerictextbox .k-link {
      display: none;
    }

    #{$materialize-kendo-input-selector} {
      border: none;

      .k-select {
        border: none;
      }

      .k-input {
        height: 28px;
        padding: 2px 2px 2px 4px;
        text-indent: 0;
      }
    }

    input {
      padding: 2px 4px;
      border: none;
    }
  }

  th {
    text-align: center !important;
    //padding-left: 20px;
    //padding-right: 20px;
  }
}

.k-grid-header-wrap thead tr {
  border-color: rgba(33, 37, 41, 0.125) !important;
}

.k-filter-menu {
  .k-input.k-textbox,
  .k-textarea,
  .k-textbox {
    border-width: 1px;
  }
}

.k-grid.transparent {
  .k-grid-header {
    border-color: rgba(0, 0, 0, 0.03);
    background-color: rgba(0, 0, 0, 0.03);
  }

  background-color: transparent;
}

.k-dropdown .k-dropdown-wrap:hover, 
.k-dropdown .k-dropdown-wrap.k-state-hover, .k-dropdowntree .k-dropdown-wrap.k-state-hover {
  background-color: transparent;
}

.k-dropdown .k-dropdown-wrap:hover, 
.k-dropdown .k-dropdown-wrap.k-state-focused, .k-dropdowntree .k-dropdown-wrap.k-state-focused {
  background-color: transparent;
  box-shadow: none;
  //box-shadow: 0 0 0 0.2rem rgb(0 188 212 / 25%)
}

.k-textbox:focus, .k-textbox.k-state-focus, .k-textbox.k-state-focused,
.k-textbox:focus-within {
  box-shadow: none;
}

.k-pager-numbers .k-link {
  border-color: transparent;
}

.k-pager-nav.k-link {
  border-color: transparent;
}