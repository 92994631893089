.btn {
  @include media-breakpoint-down(lg) {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  &:not(.btn-standard) {
    @include kee-box-shadow(1);
    text-decoration: none;
    text-align: center;
    letter-spacing: .5px;
    white-space: normal;
    text-transform: uppercase;
    cursor: pointer;
    border: 0;

    color: $white;
    background-color: $secondary;

    &:hover {
      @include kee-box-shadow(1.5);
      background-color: lighten($secondary, 5%);
    }

    &:focus {
      outline: none;
      background-color: lighten($secondary, 4%);
    }
  }

  &.small {
    font-size: .8rem;
    padding: 2px 8px;
  }
}

.btn-standard {
  box-shadow: none !important;
}

.btn-group {
  &.btn-auto {
    .btn {
      flex: 0 1 auto;
      box-shadow: none !important;
    }
  }
}

.btn-floating {
  @include kee-box-shadow(1);
  overflow: hidden;
  z-index: 1;
  padding: 0;
  border-radius: 50%;
  transition: .3s;
  cursor: pointer;
  vertical-align: middle;
}

.btn-light {
  background-color: #F9F5FF;
}