@each $color_name, $color in $materialize-colors {
   @each $color_type, $color_value in $color{
    @if $color_type == "base" {
      .document-status-#{$color_name} {
        box-shadow: 6px 6px 5px 0 rgba($color_value, 0.16), 6px 6px 10px 0 rgba($color_value, 0.12) !important;
      }
    }
    @else {
      .document-status-#{$color_name}.#{$color_type} {
        box-shadow: 6px 6px 5px 0 rgba($color_value, 0.16), 6px 6px 10px 0 rgba($color_value, 0.12) !important;
      }
    }
  }
}

.identity-document {
  @include document-specimen("specimenCI.png");
}

.registration-document {
  @include document-specimen("specimenCUI.png");
}

.contract-document {
  @include document-specimen("specimenContract.png");
}

.constitutive-act {
  @include document-specimen("specimenActConstitutiv.png");
}

.pdf-download {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  @include download-icon("pdf-download.png");
}

.xls-download {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  @include download-icon("xls-download.png");
}

.dbf-download {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  @include download-icon("dbf-download.png");
}

.csv-download {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  @include download-icon("csv-download.png");
}

.word-document {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  @include download-icon("word.png");
}

.excel-document {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  @include download-icon("excel.png");
}

.pdf-document {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  @include download-icon("pdf.png");
}

.general-document {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  @include download-icon("document.png");
}