.materialize.check-box,
.materialize.form-group {
  span.invalid-feedback {
    position: relative;
    left: 0;
    top: -.30rem;
    font-size: .7rem;
    height: 0 !important;
    display: block;
    color: $danger;
    transform: translateY(0%);

    ul {
      margin: 0;

      li {
        list-style: none;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  span.feedback {
    position: relative;
    left: 0;
    top: -.15rem;
    text-align: left;
    font-size: .7rem;
    height: 0 !important;
    display: block;
    transform: translateY(0%);
  }
}
