@import 'mixins/shadows';

// Z-levels
.z-depth-0 {
	@include kee-box-shadow(0);
}
.z-depth-1{
	@include kee-box-shadow(1);
}
.z-depth-1-half{
	@include kee-box-shadow(1.5);
}
.z-depth-2{
	@include kee-box-shadow(2);
}
.z-depth-3{
	@include kee-box-shadow(3);
}
.z-depth-4{
	@include kee-box-shadow(4);
}
.z-depth-5{
	@include kee-box-shadow(5);
}

.hoverable:hover {
	transition: box-shadow .25s;
	@include kee-box-shadow(2);
}