@import 'forms/input';
@import 'forms/checkbox';
@import 'forms/multiselect';
@import 'forms/radio';
@import 'forms/validation';

label {
  font-size: $label-font-size;
  color: $input-border-color;
}

.edit-title-head {
  min-width: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  .title-text {
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
