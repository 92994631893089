.materialize {
  &.form-group {
    .k-multiselect-wrap {
      .k-input {
        min-height: $materialize-input-height;
        height: auto !important;
      }

      .k-button {
        border-radius: 3px;
        color: $input-text;
        border-color: kee-color('grey', 'lighten-3');
        background-color: kee-color('grey', 'lighten-2');

        .k-select {
          color: $input-color!important;
        }
      }

      li {
        &.k-button {
          margin-bottom: 0 !important;
          margin-top: .5rem !important;
        }
      }

      min-height: $materialize-input-height;
      height: auto !important;
    }
  }
}

.no-clear-value {
  .k-multiselect-wrap {
    .k-clear-value {
      display: none !important;
    }
  }
}
