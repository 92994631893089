
/* CUSTOM CSS CHECKBOXES */
.materialize {
  /* Remove default checkbox */
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
    //visibility: hidden;
  }

  // Checkbox Styles
  [type="checkbox"] {

    // Text Label Style
    + label {
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      display: inline-block;
      height: 25px;
      line-height: 25px;
      font-size: 1rem;

      -webkit-user-select: none;
      /* webkit (safari, chrome) browsers */
      -moz-user-select: none;
      /* mozilla browsers */
      -khtml-user-select: none;
      /* webkit (konqueror) browsers */
      -ms-user-select: none;
      /* IE10+ */
    }

    /* checkbox aspect */
    + label:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 18px;
      height: 18px;
      z-index: 0;
      border: 2px solid $radio-empty-color;
      border-radius: 1px;
      margin-top: 2px;
      @include transition(.2s);
    }

    &:not(:checked):disabled + label:before {
      border: none;
      background-color: $input-disabled-color;
    }

    &:focus + label:before {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }

    &:focus:checked + label:before {
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    }
  }

  [type="checkbox"]:checked {
    + label:before {
      top: -4px;
      left: -3px;
      width: 12px;
      height: 22px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid $radio-fill-color;
      border-bottom: 2px solid $radio-fill-color;
      transform: rotate(40deg);
      backface-visibility: hidden;
      transform-origin: 100% 100%;
    }

    &:disabled + label:before {
      border-right: 2px solid $input-disabled-color;
      border-bottom: 2px solid $input-disabled-color;
    }
  }

  .show-enabled & {
    [type="checkbox"]:checked+label:before {
      border-right: 2px solid $radio-fill-color !important;
      border-bottom: 2px solid $radio-fill-color !important;
    }
  }

  /* Indeterminate checkbox */
  [type="checkbox"]:indeterminate {
    +label:before {
      // left: -10px;
      // top: -11px;
      // width: 10px;
      // height: 22px;
      // border-top: none;
      // border-left: none;
      // border-right: 2px solid $radio-fill-color;
      // border-bottom: none;
      // transform: rotate(90deg);
      // backface-visibility: hidden;
      // transform-origin: 100% 100%;

      /*font-family: "Material-Design-Icons";
      content: "\e7c3" !important;
      float: left;
      color: #9e9e9e;
      font-size: 1.6rem;
      margin-right: 1rem;
      border: none;*/

      width: 16px;
      height: 15px;
      background-color: $radio-fill-color;
      clip-path: inset(5% 5% 5% 5%);
      border: none;
    }

    // Disabled indeterminate
    &:disabled + label:before {
      // border-right: 2px solid $input-disabled-color;
      // background-color: transparent;
    }

    &:focus + label:before {
      box-shadow: none;
    }
  }

  // Filled in Style
  [type="checkbox"].filled-in {
    // General
    + label:after {
      border-radius: 2px;
    }

    + label:after,
    + label:before {
      content: '';
      left: 0;
      position: absolute;
      /* .1s delay is for check animation */
      transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
      z-index: 1;
    }

    // Unchecked style
    &:not(:checked) + label:before {
      width: 0;
      height: 0;
      border: 3px solid transparent;
      left: 6px;
      top: 10px;

      -webkit-transform: rotateZ(37deg);
      transform: rotateZ(37deg);
      -webkit-transform-origin: 20% 40%;
      transform-origin: 100% 100%;
    }

    &:not(:checked) + label:after {
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 2px solid $radio-empty-color;
      top: 0;
      z-index: 0;
    }

    // Checked style
    &:checked {
      + label:before {
        top: 0;
        left: 1px;
        width: 8px;
        height: 13px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid $input-bg;
        border-bottom: 2px solid $input-bg;
        -webkit-transform: rotateZ(37deg);
        transform: rotateZ(37deg);

        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
      }

      + label:after {
        top: 0;
        width: 20px;
        height: 20px;
        border: 2px solid $secondary;
        background-color: $secondary;
        z-index: 0;
      }
    }
    // Disabled style
    &:disabled:not(:checked) + label:before {
      background-color: transparent;
      border: 2px solid transparent;
    }

    &:disabled:not(:checked) + label:after {
      border-color: transparent;
      background-color: $input-disabled-bg;
    }

    &:disabled:checked + label:before {
      background-color: transparent;
    }

    &:disabled:checked + label:after {
      background-color: $input-disabled-bg;
      border-color: $input-disabled-bg;
    }
  }
}
