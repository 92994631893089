
$roboto-font-path: "../../../../font/roboto/" !default;
$icons-font-path: "../../../../font/material-design-icons/" !default;
@import 'globals/font';
@import 'globals/icons';
@import 'globals/colors';
@import 'globals/overflow';
@import 'globals/overlay';

/* Material design icons*/
$mdi-css-prefix: mdix !default;
$mdi-font-path: "../../../../node_modules/@mdi/font/fonts/" !default;
@import '@mdi/font/scss/variables';
@import "@mdi/font/scss/functions";
@import "@mdi/font/scss/path";
@import "@mdi/font/scss/core";
@import "@mdi/font/scss/icons";
@import "@mdi/font/scss/extras";
@import "@mdi/font/scss/animated";

* {
  padding: 0;
  margin: 0;
}

html {
  font-size: $font-size-root;
  height: 100%;

  &.noscroll {
    overflow: hidden;
  }
}

small {
  font-size: .8rem !important;
}

.divider {
  height: 1px;
  overflow: hidden;
  background-color: kee-color("grey", "lighten-2");
}
