html {
  height: 100%;
}

.master-container {
  display: flex !important;
  flex-wrap: wrap !important;
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.k-dialog-wrapper .k-dialog {
  max-width: 100%;
  max-height: 95%;
}

.toast-top-center {
  .ngx-toastr {
    width: fit-content !important;
    max-width: 75%;
  }

  ul {
    margin-bottom: 0;
  }
}

.a4 {
  width: 100%;
  position: relative;

  .a4-content {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:not(.landscape) {
    padding-top: 141.42%;
  }

  &.landscape {
    padding-top: 70.71%;
  }
}

kendo-popup {
  .k-popup {
    padding: 0.5rem 0;
  }
  
  .k-grid-filter-popup {
    .k-filter-menu {
      margin: 0.5rem 1.5rem 0;
    }

    .k-filter-menu-container {
        padding: 0;

        kendo-numeric-filter-menu,
        kendo-grid-string-filter-menu,
        kendo-grid-date-filter-menu {
          border-width: 1px;
          border-color: #9e9e9e;
        }

        .k-dropdown {
          border-width: 1px;
          border-color: #9e9e9e;

          &:hover {
            border-width: 1px;
            border-color: #ff4081;
          }
        }

        .k-textbox {
          border-width: 1px;
          border-color: #9e9e9e;
          padding: 0.375rem 0.5rem;
        }

        .k-textbox:focus {
          border-width: 1px;
          border-color: #ff4081;
        }

        .k-action-buttons {
          .k-button {
            flex: 1 1 50%;
          }

          .k-button[type='reset'] {
            border-color: #ff4081;
            color: #fff;
            background-color: #ff4081;

            &:hover {
              border-color: #ff0d5f;
              color: #fff;
              background-color: #ff1a68;
            }
          }

          .k-button[type='submit'] {
            border-color: #00bcd4;
            color: #fff;
            background-color: #00bcd4;

              &:hover {
                border-color: #008fa1;
                color: #fff;
                background-color: #009aae;
              }
          }
        }
      }
  }

  .k-list-optionlabel .k-state-focused .k-state-selecte {
    min-height: 1.5em;
  }
}

kendo-dialog.k-dialog-wrapper {
  kendo-dialog-actions.k-actions {
    button.k-button {
      border-color: #ff4081;
      color: #fff;
      background-color: #ff4081;

      &:hover {
        border-color: #ff0d5f;
        color: #fff;
        background-color: #ff1a68;
      }
    }

    button.k-button.k-primary {
      border-color: #00bcd4;
      color: #fff;
      background-color: #00bcd4;

        &:hover {
          border-color: #008fa1;
          color: #fff;
          background-color: #009aae;
        }
    }
  }
}
